import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLazyQuery } from "@apollo/react-hooks";
import {
  useModal,
  queryLoad,
} from "../../components";
import { GET_LEAD_FIELD_DATA } from "../../common/models/leadDataLookup";
import { GetLeadFieldData } from "../../common/models/types/GetLeadFieldData";

import { GET_SUBVERTICAL, GET_VERTICAL } from "../../common/models/vertical";
import { GetVertical } from "../../common/models/types/GetVertical";
import { GetSubVertical } from "../../common/models/types/GetSubVertical";

import { Grid, Typography } from "@material-ui/core";

import { RootState } from "../../state";

import LDPUIDataTable from "../../components/LDPUIDataTable";
import { dateToPreferredTimezone, LDPUIDateFormat } from "../../common/utils/date";
import { createDateFilter, leadsSoldFilter, stringColumnFilter } from "../../components/customMuiDatatableFilter";

interface Props {
  dispatch?: Function;
};

const LeadFieldDataList = ({ dispatch }: Props) => {
  const [getLeadFieldData, { data, called, error, loading, refetch }] = useLazyQuery<GetLeadFieldData>(GET_LEAD_FIELD_DATA);

  const [mappedLeadFieldData, setMappedLeadFieldData] = useState<any>();

  const [
    getAllVertical,
    { data: verticalData, error: verticalError, loading: verticalLoading },
  ] = useLazyQuery<GetVertical>(GET_VERTICAL);

  const [
    getAllSubVertical,
    {
      data: subVerticalData,
      error: subVerticalError,
      loading: subVerticalLoading,
    },
  ] = useLazyQuery<GetSubVertical>(GET_SUBVERTICAL);

  useEffect(() => {
    getAllVertical();
  }, []);

  useEffect(() => {
    if (verticalData || verticalError)
      getAllSubVertical();
  }, [verticalData, verticalError]);

  useEffect(() => {
    if (data && verticalData && subVerticalData) {
      setMappedLeadFieldData(data.LDPIngestQueryGroup?.LeadDataLookup?.map(leadFieldData => {
        return {
          ...leadFieldData,
          VerticalName: verticalData?.LDPConfigQueryGroup?.Vertical?.find(v => v?.VerticalId === leadFieldData?.VerticalId)?.VerticalName || null,
          SubVerticalName: subVerticalData?.LDPConfigQueryGroup?.SubVertical?.find(v => v?.SubVerticalId === leadFieldData?.SubVerticalId)?.SubVerticalName || null,
        };
      }));
    }
  }, [data, verticalData, subVerticalData]);

  const columnsMUI = [
    {
      name: "LeadDataLookupId",
      label: "LeadDataLookupId",
      options: {
        viewColumns: false,
        filter: false,
      },
    },
    {
      name: "LeadDataColumnName",
      label: "Field Name",
      options: {
        viewColumns: false,
        filter: true,
        ...stringColumnFilter
      },
    },
    {
      name: "LeadDataValue",
      label: "LeadDataValue",
      options: {
        hint: "Each distinct value for the given field name",
        viewColumns: false,
        filter: true,
        ...leadsSoldFilter
      },
    },
    {
      name: "VerticalName",
      label: "VerticalName",
      options: {
        viewColumns: false,
        filter: true,
        ...stringColumnFilter
      },
    },
    {
      name: "SubVerticalName",
      label: "SubVerticalName",
      options: {
        viewColumns: false,
        filter: true,
        ...stringColumnFilter
      },
    },
    {
      name: "ModifiedDate",
      label: "ModifiedDate",
      options: {
        viewColumns: false,
        filter: true,
        customBodyRender: (value: any, tableMeta: any) => (dateToPreferredTimezone(value)),
        ...createDateFilter(LDPUIDateFormat)
      },
    },
    {
      name: "CreatedDate",
      label: "CreatedDate",
      options: {
        viewColumns: false,
        filter: true,
        customBodyRender: (value: any, tableMeta: any) => (dateToPreferredTimezone(value)),
        ...createDateFilter(LDPUIDateFormat)
      },
    },
  ];

  const { Modal, closeModal, openModal } = useModal();

  useEffect(() => {
    getLeadFieldData();
  }, []);

  const options: any = {
    filterType: "checkbox",
    selectableRows: "none",
    responsive: "vertical",
    sortOrder: {
      name: 'LeadDataColumnName',
      direction: 'asc'
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        {queryLoad([!!loading], [error]) || (
          <LDPUIDataTable
            ldpTableId="lead-field-data-list"
            restoreFilters={true}
            title={<Typography variant='h6'>Lead Field Data</Typography>}
            data={mappedLeadFieldData}
            columns={columnsMUI}
            options={options}
          />
        )}
      </Grid>
      <Modal />
    </Grid>
  );
};

export default connect((state: RootState) => ({
}), null)(LeadFieldDataList);
