import React, { useEffect, useState } from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { ContentContainer, Layout, PrivateRoute } from "../components";
import LeadFieldDataList from "../views/leadfielddata/leadFieldDataList";
import { navigate } from "gatsby";
import { connect } from "react-redux";
import { RootState } from "../state";
import { TabProps, TabsContainer } from "../components/tabs";
import { updateTabContainers } from "../state/tabsReducer";

interface Props {
    dispatch: Function;
    leadFieldDataTabs: any;
}

const TAB_CONTAINER = "lead-field-data-tabs";

const LeadFieldData = ({ leadFieldDataTabs, dispatch }: Props) => {

    const [tabFocus, setTabFocus] = useState<number>(0);
    const [_leadFieldDataTabs, setLeadFieldDataTabs] = useState<TabProps[]>([]);

    const handleTabChangeFocus = (props: { tabs: TabProps[], focus: number, permalink: string, tabsContainerId: string }) => {
        if (props.permalink) navigate(props.permalink);
        dispatch(
            updateTabContainers({
                [props.tabsContainerId]: {
                    tabFocus: props.focus,
                    tabs: props.tabs,
                }
            })
        );
    };

    useEffect(() => {
        if (!leadFieldDataTabs.tabContainers[TAB_CONTAINER]) {
            //Initialize Redux tabsContainer for reportss
            let initialTabContainerFocus = 0;
            let initialTabs: TabProps[] = [
                {
                    title: "Lead Field Data",
                    tabId: "lead-field-data-list",
                    content: <LeadFieldDataList />,
                    permalink: `/leadfielddata/`,
                },
            ];

            const initialReportsTabContainer = {
                [TAB_CONTAINER]: {
                    tabFocus: initialTabContainerFocus,
                    tabs: initialTabs,
                }
            };

            dispatch(updateTabContainers(initialReportsTabContainer));
        } else {
            if (leadFieldDataTabs.tabContainers[TAB_CONTAINER]) {
                setLeadFieldDataTabs(leadFieldDataTabs.tabContainers[TAB_CONTAINER].tabs);
                setTabFocus(leadFieldDataTabs.tabContainers[TAB_CONTAINER].tabFocus);
            }
        }
    }, [leadFieldDataTabs])

    return (
        <ContentContainer>
            <TabsContainer
                tabs={_leadFieldDataTabs}
                onCloseTab={setLeadFieldDataTabs}
                tabFocus={tabFocus}
                onChange={handleTabChangeFocus}
                tabsContainerId={TAB_CONTAINER}
            />
        </ContentContainer>
    );
};

const LeadFieldDataWrapper = connect((state: RootState) => ({
    leadFieldDataTabs: state.tabsSection,
}), null)(LeadFieldData);

export default ({ location }: RouteComponentProps) => {
    return (
        <Layout>
            <Router
                style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                }}
            >
                <PrivateRoute default component={LeadFieldDataWrapper} path="/leadfielddata" pagetitle="LDP Lead Data Lookup" />
            </Router>
        </Layout>
    );
};


